import styles from './change-contact-details-form.module.scss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  GoldButton,
  SelectDropdown,
  Spinner,
} from '@orascom/common-components';
import successIcon from '../../../assets/icons/success.svg';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { OptionValue, ServiceRequestTypeEnum } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../../assets/icons/error.svg';
import { useState } from 'react';
import { ServiceRequestFormData } from '../../../../src/definitions/interfaces/service-requests.interface';
import {
  ContactTimeEnum,
  ContactTypeEnum,
} from '../../../../src/definitions/interfaces/contact.interface';

interface FormInitialData {
  changesRequested: string[];
  contactType: OptionValue | null;
  contactTime: OptionValue | null;
  message: string;
}

export interface ChangeContactDetailsData {
  changes_requested: string[];
  contact_time: string;
  communication_channel: string;
  message: string;
}

export interface ChangeContactDetailsFormProps {
  unitId: number;
  serviceType: ServiceRequestTypeEnum;
  customerId: string;
  onClose: () => void;
}

export function ChangeContactDetailsForm(
  props: Readonly<ChangeContactDetailsFormProps>
) {
  const { t } = useTranslation();
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleSubmitForm = (
    formData: FormInitialData,
    resetForm: () => void
  ) => {
    if (formData.contactTime && formData.contactType) {
      const mappedData: ServiceRequestFormData = {
        change_contact_details: formData.changesRequested.join(),
        customer_source_id: props.customerId,
        preferred_way_of_contact: Number(formData.contactType.value),
        preferred_time_of_contact: Number(formData.contactTime.value),
        request_details: formData.message,
        service_slug: props.serviceType,
      };
      console.log(mappedData);

      if (props.unitId) {
        PreDeliveryUserUtilities.submitServiceRequest(
          props.unitId.toString(),
          mappedData
        )
          .then(() => {
            setSuccessMsg(true);
          })
          .catch(() => setErrorMsg(true))
          .finally(() => resetForm());
      }
    }
  };
  const UnitServicesSchema = Yup.object().shape({
    changesRequested: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required(t('fieldIsRequired')),
    contactType: Yup.object().nullable().required(t('fieldIsRequired')),
    contactTime: Yup.object().nullable().required(t('fieldIsRequired')),
    message: Yup.string().max(250, 'maximumCharacters250'),
  });

  if (errorMsg) {
    return (
      <div>
        <div className={styles['error']}>
          <p className={styles['error-title']}>
            <img src={errorSubmitIcon} role="presentation" alt="" />
            <span>{t('formSubmitErrorTitle')}</span>
          </p>
          <p className={styles['error-message']}>
            {t('formSubmitErrorMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setErrorMsg(false)}>
          {t('Try again')}
        </GoldButton>
      </div>
    );
  }
  if (successMsg) {
    return (
      <div>
        <div className={styles['success']}>
          <p className={styles['success-title']}>
            <img src={successIcon} role="presentation" alt="" />
            <span>{t('formSubmitSuccessTitle')}</span>
          </p>
          <p className={styles['success-message']}>
            {t('formSubmitSuccessMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setSuccessMsg(false)}>
          {t('Submit another request')}
        </GoldButton>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        changesRequested: [],
        contactType: null,
        contactTime: null,
        message: '',
      }}
      validationSchema={UnitServicesSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmitForm(values, resetForm);
      }}
    >
      {({ errors, touched, setFieldValue, values, isSubmitting }) =>
        !isSubmitting ? (
          <Form>
            <legend>{t('contactFormDesc')}</legend>
            <div
              role="group"
              aria-labelledby="checkbox-group"
              className="checkbox-input-wrapper"
            >
              <label htmlFor="changesRequested">
                {t('contactCheckboxesLabel')}
              </label>
              <label>
                <Field
                  type="checkbox"
                  name="changesRequested"
                  value={t('name')}
                />
                {t('name')}
              </label>
              <label>
                <Field
                  type="checkbox"
                  name="changesRequested"
                  value={t('phoneNumber')}
                />
                {t('phoneNumber')}
              </label>
              <label>
                <Field
                  type="checkbox"
                  name="changesRequested"
                  value={t('emailAddress')}
                />
                {t('emailAddress')}
              </label>
              <label>
                <Field
                  type="checkbox"
                  name="changesRequested"
                  value={t('billingInformation')}
                />
                {t('billingInformation')}
              </label>
              <label>
                <Field
                  type="checkbox"
                  name="changesRequested"
                  value={t('homeAddress')}
                />
                {t('homeAddress')}
              </label>
              <label>
                <Field
                  type="checkbox"
                  name="changesRequested"
                  value={t('other')}
                />
                {t('other')}
              </label>
              {errors.changesRequested && touched.changesRequested ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.changesRequested}
                </p>
              ) : null}
            </div>
            <div className="input-wrapper">
              <label htmlFor="contactType">{t('preferredWayOfContact')}*</label>
              <SelectDropdown
                name="contactType"
                className={`${styles['select']} ${
                  errors.contactType && touched.contactType ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactType', option)}
                options={[
                  { value: ContactTypeEnum.MOBILE, label: 'Phone call' },
                  { value: ContactTypeEnum.WHATS_APP, label: 'Whatsapp' },
                  { value: ContactTypeEnum.EMAIL, label: 'Email' },
                  {
                    value: ContactTypeEnum.VIRTUAL_MEETING,
                    label: 'virtual meeting',
                  },
                ]}
                selectedOption={values.contactType ?? null}
              />
              {errors.contactType && touched.contactType ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.contactType}
                </p>
              ) : null}
            </div>
            <div className="input-wrapper">
              <label htmlFor="contactTime">
                {t('preferredTimeOfContact')}*
              </label>
              <SelectDropdown
                name="contactTime"
                className={`${styles['select']} ${
                  errors.contactTime && touched.contactTime ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactTime', option)}
                options={[
                  { value: ContactTimeEnum.MORNING, label: 'Morning' },
                  { value: ContactTimeEnum.AFTERNOON, label: 'Afternoon' },
                  { value: ContactTimeEnum.NIGHT, label: 'Night' },
                ]}
                selectedOption={values.contactTime ?? null}
              />
              {errors.contactTime && touched.contactTime ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.contactTime}
                </p>
              ) : null}
            </div>

            <div className="input-wrapper">
              <label htmlFor="requestDetails">{`${t('message')} ${t(
                'optional'
              )}`}</label>
              <Field
                name="message"
                as="textarea"
                rows="6"
                className={errors.message && touched.message ? 'error' : ''}
                placeholder={t('messagePlaceHolder')}
              />
              {errors.message && touched.message ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.message}
                </p>
              ) : null}
            </div>
            <GoldButton type="submit" disabled={isSubmitting}>
              {t('submitRequest')}
            </GoldButton>
          </Form>
        ) : (
          <div className={styles['submitting']}>
            <Spinner />
            <p>{t('sendingRequest')}</p>
          </div>
        )
      }
    </Formik>
  );
}

export default ChangeContactDetailsForm;
