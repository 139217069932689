/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Numeral from 'numeral';
import styles from './sign-contract.module.scss';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import tickIcon from '../../../assets/icons/tick.svg';
import successIcon from '../../../assets/icons/success.svg';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';
import downloadIcon from '../../../assets/icons/download.svg';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { ServiceRequestTypeEnum, UnitDetails } from '@orascom/api-interfaces';

import { GoldButton, Loader, Spinner } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../api/routes';
import { Field, Form, Formik } from 'formik';
import {
  ServiceRequestFormData,
  SignContractMethod,
  SignContractRequestInitialData,
  SignContractRequestedOption,
} from '../../../definitions/interfaces/service-requests.interface';
import { USER_CONTEXT } from 'apps/orascom-shopper-app/src/contexts/user-context';
import { getCustomerId } from 'apps/orascom-shopper-app/src/utils/common.utils';
import { useSignContractSchema } from './use-sign-contract-schema';
import SignContractOfflineFormFields from './sign-contract-offline-form-fields';
import SignContractViaDhlFormFields from './sign-contract-via-dhl-form-fields';
import { useGetMyUnits } from '../hooks/use-get-my-units';

export function SignUnitContract() {
  const { t } = useTranslation();
  const { unitId } = useParams<{ unitId: string }>();

  const [signOnlineContract, setSignOnlineContract] = useState<boolean>(false);
  const [unitDetails, setUnitDetails] = useState<UnitDetails>();

  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [customerId, setCustomerId] = useState<string>('');

  const userContext = useContext(USER_CONTEXT);
  const [SignContractFormSchema] = useSignContractSchema();

  const [myUnits, loadingMyUnits] = useGetMyUnits();

  useEffect(() => {
    if (userContext?.userReservationDetails) {
      const foundCustomerId = getCustomerId(
        Number(unitId),
        userContext.userReservationDetails
      );

      if (foundCustomerId) {
        setCustomerId(foundCustomerId);
      }
    }
  }, [userContext.userReservationDetails]);

  useEffect(() => {
    if (unitId && myUnits.length) {
      setUnitDetails(myUnits.find((unit) => unit.id.toString() === unitId));
    }
  }, [unitId, myUnits]);

  if (loadingMyUnits) {
    return <Loader />;
  }

  const handleSubmitForm = (formData: SignContractRequestInitialData) => {
    const offlineContract = formData.signContractMethod === 'offline';
    const DHLContract = formData.signContractMethod === 'dhl';

    const mappedData: ServiceRequestFormData = {
      customer_source_id: customerId,
      // preferred_time_of_contact: 1,
      preferred_way_of_contact: DHLContract
        ? Number(formData.contactType?.value)
        : null,
      request_details: DHLContract
        ? `
        Governorate: ${formData.governorate}
        District: ${formData.district}
        Address: ${formData.address}
        FloorNumber: ${formData.floorNumber}
        Apartment: ${formData.apartment}
        Additional info: ${formData.additionalInfo}`
        : formData.additionalInfo,
      service_slug: ServiceRequestTypeEnum.SIGN_CONTRACT,
      sign_contract_place: offlineContract
        ? formData.meetingLocation?.value.toString()
        : '',
      sign_contract_visit_date_and_time: offlineContract
        ? `${formData.meetingDate}, ${formData.meetingTime}`
        : `${formData.deliveryDate}, ${formData.deliveryTime}`,
      sign_contract_requested_option: offlineContract
        ? SignContractRequestedOption.SIGN_OFFLINE
        : SignContractRequestedOption.REQUEST_DELIVARY,
    };

    if (unitId) {
      PreDeliveryUserUtilities.submitServiceRequest(unitId, mappedData)
        .then(() => {
          setSuccessMsg(true);
        })
        .catch(() => setErrorMsg(true));
    }
  };

  const errorMessageSection = () => (
    <div>
      <h1 className="orascom__sub-title">{t('signContract')}</h1>

      <div className={styles['error']}>
        <p className={styles['error-title']}>
          <img src={errorSubmitIcon} role="presentation" alt="" />
          <span>{t('formSubmitErrorTitle')}</span>
        </p>
        <p className={styles['error-message']}>{t('formSubmitErrorMessage')}</p>
      </div>
      <GoldButton onClick={() => setErrorMsg(false)}>
        {t('Try again')}
      </GoldButton>
    </div>
  );

  const successMessageSection = () => (
    <div>
      <h1 className="orascom__sub-title">{t('signContract')}</h1>

      <div className={styles['success']}>
        <p className={styles['success-title']}>
          <img src={successIcon} role="presentation" alt="" />
          <span>{t('formSubmitSuccessTitle')}</span>
        </p>
        <p className={styles['success-message']}>
          {t('formSubmitSuccessMessage')}
        </p>
      </div>
      <GoldButton onClick={() => setSuccessMsg(false)}>
        {t('Submit another request')}
      </GoldButton>
    </div>
  );

  const signContractSection = () => (
    <div className={styles['details-payments']}>
      <h1 className="orascom__sub-title">{t('signContract')}</h1>
      {signOnlineContract ? (
        <div className={styles['success']}>
          <div>
            <img src={tickIcon} alt="" role="presentation" />
            <h2>{t('Contract Signed Successfully')}</h2>
          </div>
          <p>
            Thank You! Your unit contract has been signed online successfully.
            Please note you can download a copy of your contract at any time
            from your unit documents.
          </p>
          <a
            className={styles['download']}
            href="/"
            download
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles['icon']}>
              <img src={downloadIcon} alt="" role="presentation" />
            </div>
            Download Unit Contract
          </a>
        </div>
      ) : (
        <>
          <h2>{t('chooseMethodOfSignature')}</h2>
          <Formik
            initialValues={{
              signContractMethod: 'offline' as SignContractMethod,
              meetingLocation: null,
              meetingDate: '',
              meetingTime: '',
              additionalInfo: '',
              governorate: '',
              district: '',
              address: '',
              floorNumber: '',
              apartment: '',
              contactType: null,
              deliveryDate: '',
              deliveryTime: '',
            }}
            validationSchema={SignContractFormSchema}
            onSubmit={(values, { resetForm }) => {
              console.log(values, resetForm);
              handleSubmitForm(values);
            }}
          >
            {({ errors, touched, setFieldValue, values, isSubmitting }) => {
              return !isSubmitting ? (
                <Form className="reservation-form">
                  <div role="group" className="reservation-radio-buttons--big">
                    <div>
                      <label>
                        <div>
                          <p>{t('scheduleOfflineMeeting')}</p>
                          <Field
                            type="radio"
                            name="signContractMethod"
                            value="offline"
                          />
                        </div>
                      </label>
                      <label>
                        <div>
                          <p>{t('requestToBeSentViaDHL')}</p>
                          <Field
                            type="radio"
                            name="signContractMethod"
                            value="dhl"
                          />
                        </div>
                      </label>
                      {/*
                        <label>
                          <div>
                            <p>{t('Sign it Online')}</p>
                            <Field
                              type="radio"
                              name="signContractMethod"
                              value="online"
                            />
                          </div>
                        </label> */}
                    </div>
                  </div>

                  {values.signContractMethod === 'offline' && (
                    <SignContractOfflineFormFields
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.signContractMethod === 'dhl' && (
                    <SignContractViaDhlFormFields
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {/* {values.signContractMethod === 'online' && (
                      <div className="reservation-sub-form">
                        <h2 className="reservation-form-title">
                          {t('Sign it Online')}
                        </h2>
                        <p>
                          Sign your unit contract online below using
                          e-signature.
                        </p>
                      </div>
                    )} */}

                  <div className="reservation-form-buttons">
                    {values.signContractMethod === 'online' ? (
                      <GoldButton
                        onClick={() => {
                          setSignOnlineContract(true);
                        }}
                      >
                        Sign Contract{' '}
                      </GoldButton>
                    ) : (
                      <GoldButton type="submit" disabled={isSubmitting}>
                        submit
                      </GoldButton>
                    )}
                  </div>
                </Form>
              ) : (
                <div className={styles['submitting']}>
                  <Spinner />
                  <p>{t('sendingRequest')}</p>
                </div>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );

  // if (!unitInstallments?.installments?.length) {
  //   return (
  //     <div className={`${styles['unit-payment']} pre-delivery-container`}>
  //       <header className={styles['header']}>
  //         <NavLink
  //           to={ROUTES.Pre_Delivery_Unit.getPath?.(String(unitId))}
  //           className="back-button"
  //         >
  //           <img src={backArrowIcon} alt="side-menu-icon" />
  //           <>{t('backToUnitDetails')}</>
  //         </NavLink>
  //         <div className={styles['header-details']}>
  //           <NavLink to={ROUTES.Pre_Delivery_Home.path}>
  //             <>{t('unitDetails')}</>
  //           </NavLink>
  //           <span>/</span>
  //           <p>{t('Sign Contract')}</p>
  //         </div>
  //       </header>
  //       <h1 className="orascom__sub-title">no payments found for this unit</h1>
  //     </div>
  //   );
  // }

  // const installmentsDuration = differenceInYears(
  //   new Date(
  //     unitInstallments.installments[unitInstallments.installments?.length - 1]
  //       .payment_date * 1000
  //   ),
  //   new Date(unitInstallments.installments[0].payment_date * 1000)
  // );

  return (
    <div className={`${styles['sign-contract']} pre-delivery-container`}>
      <header className={styles['header']}>
        <NavLink
          to={ROUTES.Pre_Delivery_Unit.getPath?.(String(unitId))}
          className="back-button"
        >
          <img src={backArrowIcon} alt="side-menu-icon" />
          <>{t('backToUnitDetails')}</>
        </NavLink>
        <div className={styles['header-details']}>
          <NavLink to={ROUTES.Pre_Delivery_Home.path}>
            <>{t('unitDetails')}</>
          </NavLink>
          <span>/</span>
          <p>{t('signContract')}</p>
        </div>
      </header>
      <div className={styles['details']}>
        {errorMsg ? errorMessageSection() : null}
        {successMsg ? successMessageSection() : null}
        {!successMsg && !errorMsg ? signContractSection() : null}
        <div className={styles['details-info']}>
          <div className={styles['details-info-image']}>
            <img src={unitDetails?.cover_image} alt="" loading="lazy" />
          </div>
          <div className={styles['details-info-content']}>
            <p>
              {unitDetails?.project.destination.name} -{' '}
              {unitDetails?.project.name}
            </p>
            <h3>{unitDetails?.unit_type.name}</h3>
            <div className={styles['details-info-content-items']}>
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('unitNumber')}</label>
                <p>{unitDetails?.name}</p>
              </div>
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('unitPrice')}</label>
                <p>
                  {Numeral(unitDetails?.price).format('0,0')}{' '}
                  {unitDetails?.currency}
                </p>
              </div>
              {/* {unitInstallments.total_maintenance_fees ? (
                <div className={styles['details-info-content-item']}>
                  <label htmlFor="">{t('maintenanceFees')}</label>
                  <p>
                    {Numeral(unitInstallments.total_maintenance_fees).format(
                      '0,0'
                    )}{' '}
                    {unitDetails?.currency}
                  </p>
                </div>
              ) : null}
              {unitInstallments.total_clubhouse_fees ? (
                <div className={styles['details-info-content-item']}>
                  <label htmlFor="">{t('clubHouseFees')}</label>
                  <p>
                    {Numeral(unitInstallments.total_clubhouse_fees).format(
                      '0,0'
                    )}{' '}
                    {unitDetails?.currency}
                  </p>
                </div>
              ) : null}
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('installmentsDuration')}</label>
                <p>
                  {installmentsDuration} {t('years')}
                </p>
              </div> */}
              {/* <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('purchaseDate')}</label>
                <p>
                  {new Date(
                    unitPaymentsDetails.purchase_date * 1000
                  ).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </div> */}
              {/* <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('deliveryYear')}</label>
                <p>
                  {t('q')}
                  {Math.floor(
                    new Date(
                      unitPaymentsDetails.delivery_year * 1000
                    ).getMonth() /
                      3 +
                      1
                  )}{' '}
                  {new Date(
                    unitPaymentsDetails.delivery_year * 1000
                  ).getFullYear()}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUnitContract;
