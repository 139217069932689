import { useEffect, useState } from 'react';
import { PriceValue } from '@orascom/api-interfaces';
import PropertyCard from '../../components/common/property-card/property-card';
import { NavyButton } from '../../components/common/button/button';
import { useTranslation } from 'react-i18next';
import {
  GoldButton,
  Modal,
  SkeletonUnit,
  UnitsFilters,
} from '@orascom/common-components';
import CompareButton from '../../components/common/compare-button/compare-button';
import styles from './available-units.module.scss';
import { Filter as FilterApi } from '../../utils/filter-utils';
import {
  useCurrencyContext,
  useCurrencyConverter,
  useDisclosure,
  usePriceSlider,
  useUnitFilters,
  useUnits,
} from '@orascom/utils';
import { Unit as UnitApi } from '../../utils/unit.utils';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import { useSearchParams } from 'react-router-dom';

export function AvailableUnits() {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const { selectedCurrency, convertPrice } = useCurrencyConverter();
  const [searchParams] = useSearchParams();
  // const { searchParams, params, onClearAllFilters } = useClearSearchParams();
  const params: string[] = [];
  searchParams.forEach((value, key) => {
    params.push(value);
  });

  const unitsData = useUnits({
    getUnits: UnitApi.getUnits,
    selectedCurrency,
    convertPrice,
  });

  // destructure units data
  const { units, loadMoreUnits, paginationData, hasMoreUnits } = unitsData;
  const { isLoadingUnits, isLoadingMoreUnits } = unitsData;

  const { filters, isLoading: isLoadingFilters } = useUnitFilters({
    getUnitFilters: FilterApi.getUnitFilters,
  });

  const {
    isLoading: isLoadingCurrency,
    setActiveCurrency,
    setDisableCurrencySetting,
  } = useCurrencyContext();

  const { priceSlider, setPriceSlider } = usePriceSlider(filters);

  function setPriceValue(priceValue: PriceValue) {
    setPriceSlider({
      ...priceSlider,
      price: priceValue,
    });
  }

  useEffect(() => {
    const gounaInParams = Boolean(
      params.find((param) => param.includes('el-gouna'))
    );
    if (gounaInParams) {
      setActiveCurrency('USD');
      setDisableCurrencySetting({
        disabled: true,
        info: t(`elGounaCurrencyDisclaimer`),
      });
    }
    return () =>
      setDisableCurrencySetting({
        disabled: false,
        info: '',
      });
  }, [searchParams]);

  const NoUnitsMessage = () => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    return (
      <div className={`${styles['no-results']} container`}>
        <h4>{t('noUnitsResults')}</h4>
        <p>{t('noUnitsResultsSubtitle')}</p>
        <p>{t('noUnitsResultsAction')}</p>
        <GoldButton onClick={onOpen}>{t('notifyMe')}</GoldButton>
        <Modal show={isOpen} onClose={onClose} title={t('talkToSales')}>
          <ContactUsForm
            title={t('leaveYourContactInfo')}
            onCloseModal={onClose}
            talkToSalesForm
          />
        </Modal>
      </div>
    );
  };

  const shouldShowSkeleton = isLoadingUnits || isLoadingMoreUnits;

  const LoadMoreUnitsButton = () => (
    <div className={styles['units__button']}>
      <NavyButton onClick={loadMoreUnits} disabled={shouldShowSkeleton}>
        {t('loadMore')}
      </NavyButton>
    </div>
  );

  const HideFiltersButton = () => (
    <button
      type="button"
      onClick={() => setIsExpanded((prevState) => !prevState)}
    >
      {isExpanded ? t('hideFilters') : t('showFilters')}
    </button>
  );

  const hideFiltersClass = isExpanded ? '' : 'units__filters--hide';
  const shouldShowUnits = Boolean(units.length) && !isLoadingUnits;

  return (
    <div className={styles['units']}>
      <CompareButton />

      <div className={`${styles['units__headers']} container`}>
        <h2 className="orascom__sub-title">{t('availableUnits')}</h2>
      </div>

      <div className={`${styles['units__filters']} ${hideFiltersClass}`}>
        <div className={`${styles['units__filters-headers']} container`}>
          <h3>{t('filterBy')}</h3>
          <HideFiltersButton />
        </div>

        <UnitsFilters
          unitFilters={filters}
          isLoadingFilters={isLoadingFilters}
          showResale={true}
          expanded={isExpanded}
          priceSlider={priceSlider}
          setPriceValue={setPriceValue}
          isLoadingCurrency={isLoadingCurrency}
        />
      </div>

      {shouldShowUnits && (
        <>
          <div className={styles['units__wrapper']}>
            {units.map((unit) => (
              <PropertyCard key={unit.id} unit={unit} />
            ))}
          </div>

          {!isLoadingMoreUnits && (
            <div className={styles['units__count']}>
              <hr />
              <span>
                {t('showing')} {units?.length ?? 0} {t('unitsOutOf')}{' '}
                {paginationData?.total ?? 0}
              </span>
            </div>
          )}
        </>
      )}

      {shouldShowSkeleton && (
        <div className={styles['units__wrapper']}>
          {Array.from({ length: 12 }, (_, i) => (
            <SkeletonUnit key={i} />
          ))}
        </div>
      )}

      {hasMoreUnits && <LoadMoreUnitsButton />}

      {units.length === 0 && !shouldShowSkeleton && <NoUnitsMessage />}
    </div>
  );
}

export default AvailableUnits;
