import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export interface GenericPdfDownloaderProps {
  rootElementId: string;
  downloadFileName: string;
  buttonText: string;
  className: string;
  verticalMargin?: number;
  horizontalMargin?: number;
  extraHeight?: number;
  icon?: string;
}
export const GenericPdfDownloader = ({
  rootElementId,
  downloadFileName,
  buttonText,
  className,
  verticalMargin = 0,
  horizontalMargin,
  extraHeight = 0,
  icon,
}: GenericPdfDownloaderProps) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId) as HTMLInputElement;
    html2canvas(input, {
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295 + extraHeight;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF('p', 'mm');
      let position = 0;

      doc.addImage(
        imgData,
        'PNG',
        horizontalMargin ?? 5,
        position + verticalMargin,
        imgWidth,
        imgHeight
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          'PNG',
          horizontalMargin ?? 5,
          position + verticalMargin,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }
      doc.save(`${downloadFileName}.pdf`);
    });
  };

  return (
    <button onClick={downloadPdfDocument} className={className}>
      {buttonText}
      {icon ? (
        <span className="download-icon-wrapper">
          <img
            src={icon}
            role="presentation"
            alt=""
            className="download-icon"
            loading="lazy"
          />
        </span>
      ) : null}
    </button>
  );
};
